<template>
    <div class="news-page">
        <basic-page :pagedata="this.pageData" bannerimg="/img/news-banner.jpg">
            <div class="news-container container-beige intro container">
                <h3 class="occhiello">{{ this.lang!=defaultLang ? 'Latest News' : 'Ultime News' }}</h3>
                    <transition-group name="list" tag="div">
                        <news-tile v-for="(n, index) in newsData.slice(0, 3)" :key="'news'+index" :ndata="n"></news-tile>
                    </transition-group>
            </div>
        </basic-page>
        <div class="template-page bg-gray">
            <!--v-if="newsData.length > 3">-->
            <div class="news-container-vertical container ">
                <p class="occhiello-section">{{ this.lang!=this.defaultLang ? 'Other News' : 'Ultime News' }}</p>
                <!--<news-tile v-for="(n, index) in newsData.slice(3, newsData.length)" :key="'news'+index" :ndata="n"></news-tile>-->
                <news-tile v-for="(n, index) in newsData.slice(3, newsData.length)" :key="'news'+index" :ndata="n"></news-tile>
            </div>
        </div>
    </div>
</template>

<script>
    import NewsTile from '../components/NewsTile.vue'
    import {
        fetchNodes,
        fetchSinglePage,
        getTranslation
    } from '../libs/drupalClient'
    import BasicPage from './BasicPage.vue'

    export default {
        components: {
            NewsTile,
            BasicPage
        },
        name: 'news',
        data: () => {
            return {
                currentPageId: "d41b00ef-355f-4695-9284-dab4880c025f",
                pageData: {
                    attributes: {}
                },
                newsData: []
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            }
        },
        mounted() {
            fetchNodes('news', {
                    include: ['field_news_image']
                }, this.lang)
                .then(res => {
                    this.newsData = res.sort((a, b) => {
                        return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                    })
                    //console.log(this.newsData)
                })

            fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                })
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                .then(res => {
                    this.$router.push({path: res})
                })

                fetchNodes('news', {
                    include: ['field_news_image']
                }, this.lang)
                .then(res => {
                    this.newsData = res.sort((a, b) => {
                        return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                    })
                })

                fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                }) 
            }
        }
    }
</script>

<style lang="scss"  >
    @import "../styles/colors";


</style>